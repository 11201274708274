<template>
  <section class="page-market-buy">
    <b-row>
      <b-col
        class="d-flex align-items-center my-1"
        sm="12"
      >
        <div class="back-button cursor-pointer mr-2">
          <router-link :to="{name: 'market'}">
            <font-awesome-icon
              icon="fa-solid fa-arrow-left"
              size="lg"
              style="color: #000;"
            />
          </router-link>
        </div>
        <h2 class="page-title">
          {{ $t('Secure payment') }}
        </h2>
      </b-col>
    </b-row>
    <b-overlay
      :show="loading"
      no-center
      variant="transparent"
      rounded="sm"
    >
      <template #overlay>
        <loading-spinner />
      </template>
      <div
        v-if="product"
        class="payment-package-details"
      >
        <div class="package-left">
          <b-img
            :src="iconDiamonds1"
            width="38"
          />
        </div>
        <div class="package-right">
          <div class="package-name">
            {{ numberFormat(product.credits) }}
            {{ couponCodeBonusRatio ? `+ ${numberFormat(product.credits * couponCodeBonusRatio)}` : null }}
            {{ $t('Diamonds') }}
          </div>
          <div class="package-description">
            {{ $t('By buying {count} diamonds, you will start chatting as you wish', {count: product.credits}) }}
          </div>
        </div>
      </div>
      <b-row v-if="loading || product">
        <b-col
          md="12"
          sm="12"
        >
          <b-row>
            <b-col md="12">
              <h3 class="h3">
                {{ $t('Payment methods') }}
              </h3>
            </b-col>
            <b-col
              xl="8"
              lg="10"
              md="12"
            >
              <div
                class="accordion buying-options"
                role="tablist"
              >
                <div class="buying-option">
                  <div
                    v-b-toggle.accordion-1
                    class="buying-option-header"
                    role="tab"
                  >
                    <div>
                      {{ $t('Payment by credit card') }}
                    </div>
                    <div class="fs-14 color-gray fw-300">
                      {{ $t('You can pay securely with your credit card') }}
                    </div>
                    <span class="badge-advantage">
                      {{ $t('Most advantageous') }}
                    </span>
                  </div>
                  <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                    :visible="false"
                  >
                    <div class="buying-option-body">
                      <validation-observer
                        ref="form"
                        #default="{invalid}"
                      >
                        <b-form
                          class="mt-2"
                          @submit.prevent
                        >
                          <b-row>
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="cc-name"
                                :label="$t('Firstname')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Firstname')"
                                  vid="firstName"
                                  rules="required"
                                >
                                  <b-form-input
                                    id="cc-name"
                                    v-model="creditCard.firstname"
                                    autocomplete="cc-name"
                                    name="cc-name"
                                    :placeholder="$t('Firstname')"
                                    :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="cc-lastname"
                                :label="$t('Lastname')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Lastname')"
                                  vid="lastname"
                                  rules="required"
                                >
                                  <b-form-input
                                    id="cc-lastname"
                                    v-model="creditCard.lastname"
                                    autocomplete="cc-lastname"
                                    name="cc-lastname"
                                    :placeholder="$t('Lastname')"
                                    :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="email"
                                :label="$t('Email')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Email')"
                                  vid="email"
                                  rules="required|email"
                                >
                                  <b-form-input
                                    id="email"
                                    v-model="creditCard.email"
                                    name="email"
                                    :placeholder="$t('Email')"
                                    :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="phone"
                                :label="$t('Phone number')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Phone number')"
                                  vid="phone"
                                  rules="required"
                                >
                                  <vue-tel-input
                                    v-model="creditCard.phone"
                                    mode="auto"
                                    :input-options="{placeholder: $t('Phone number')}"
                                    valid-characters-only
                                    :style-classes="['phone-input']"
                                    @open="onDropdownOpen(true)"
                                    @close="onDropdownOpen(false)"
                                    @country-changed="countryChanged"
                                    @validate="phoneValidate"
                                  >
                                    <template v-slot:arrow-icon>
                                      <span class="font-small-3 mr-05">
                                        {{ country ? '+' + country.dialCode : '' }}
                                      </span>
                                      <font-awesome-icon
                                        v-if="open"
                                        icon="fa-solid fa-chevron-up"
                                      />
                                      <font-awesome-icon
                                        v-else
                                        icon="fa-solid fa-chevron-down"
                                      />
                                    </template>
                                  </vue-tel-input>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col sm="12">
                              <b-row>
                                <b-col
                                  md="6"
                                  sm="12"
                                >
                                  <b-form-group
                                    label-for="cc-number"
                                    :label="$t('Credit card number')"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Credit card number')"
                                      vid="number"
                                      rules="required|min:16"
                                    >
                                      <b-form-input
                                        id="cc-number"
                                        v-model="creditCard.number"
                                        v-mask="'#### #### #### ####'"
                                        masked="false"
                                        autocomplete="cc-number"
                                        name="cc-number"
                                        autocompletetype="cc-number"
                                        :placeholder="$t('Credit card number')"
                                        :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  md="2"
                                  sm="4"
                                >
                                  <b-form-group
                                    :label="$t('Month')"
                                    label-for="month"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Month')"
                                      vid="month"
                                      rules="required"
                                    >
                                      <v-select
                                        id="month"
                                        v-model="creditCard.month"
                                        :clearable="false"
                                        :options="months"
                                        autocomplete="cc-exp-month"
                                        :placeholder="$t('Month')"
                                        name="cc-exp-month"
                                        :selectable="option => ! option.includes($t('Month'))"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  md="2"
                                  sm="4"
                                >
                                  <b-form-group
                                    :label="$t('Year')"
                                    label-for="cc-exp-year"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      :name="$t('Year')"
                                      vid="year"
                                      rules="required"
                                    >
                                      <v-select
                                        id="cc-exp-year"
                                        v-model="creditCard.year"
                                        :clearable="false"
                                        :options="years"
                                        autocomplete="cc-exp-year"
                                        :placeholder="$t('Year')"
                                        name="cc-exp-year"
                                        :selectable="option => ! option.includes($t('Year'))"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  md="2"
                                  sm="4"
                                >
                                  <b-form-group
                                    label-for="cvv"
                                    label="CVV2"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="CVV2"
                                      vid="cvv"
                                      rules="required"
                                    >
                                      <b-form-input
                                        id="cvv"
                                        v-model="creditCard.cvv"
                                        v-mask="['###']"
                                        masked="false"
                                        autocomplete="cc-csc"
                                        name="cc-csc"
                                        autocompletetype="cc-csc"
                                        placeholder="CVV2"
                                        :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                      />
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                            <b-col sm="12">
                              <b-form-group
                                label-for="coupon-code"
                                :label="$t('Coupon Code')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Coupon Code')"
                                  vid="number"
                                  rules="min:4"
                                >
                                  <b-row>
                                    <b-col
                                      md="6"
                                      sm="12"
                                    >
                                      <b-input-group>
                                        <b-form-input
                                          v-model="couponCode"
                                          style="border-bottom-right-radius: 0 !important; border-top-right-radius: 0 !important;"
                                          name="couponCode"
                                          :disabled="couponCodeSucceed"
                                          :placeholder="$t('Enter Your Coupon')"
                                        />
                                        <b-input-group-append>
                                          <b-button
                                            size="sm"
                                            variant="success"
                                            :disabled="couponCodeSucceed || loading || couponCode.length < 4"
                                            @click="applyCouponCode"
                                          >
                                            {{ $t('Use Coupon') }}
                                          </b-button>
                                        </b-input-group-append>
                                      </b-input-group>
                                      <div
                                        class="mt-05 fs-14"
                                        :class="couponCodeInfoColor"
                                      >
                                        <font-awesome-icon
                                          icon="fa-solid fa-info-circle"
                                        />
                                        {{ couponCodeInfoText }}
                                      </div>
                                      <small class="text-danger">{{ errors[0] }}</small>
                                    </b-col>
                                  </b-row>
                                </validation-provider></b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col
                              class="mt-2 mb-3 pl-0"
                              xl="6"
                              lg="6"
                              md="12"
                            >
                              <b-button
                                variant="primary"
                                block
                                :disabled="loading || invalid"
                                @click="buyWithCreditCard"
                              >
                                <div class="button-secure-icon">
                                  <b-img
                                    :src="iconSecureBuying"
                                    class=""
                                    height="26"
                                  />
                                </div>
                                {{ $t('Buy') }}
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-form>
                      </validation-observer>
                    </div>
                  </b-collapse>
                </div>
                <div
                  v-if="mobileProduct"
                  class="buying-option"
                >
                  <div
                    v-b-toggle.accordion-2
                    class="buying-option-header"
                    role="tab"
                  >
                    <div>
                      {{ $t('Mobile payment') }}
                    </div>
                    <div class="fs-14 color-gray fw-300">
                      {{ $t('Make secure payments over your phone line with the mobile payment method') }}
                    </div>
                  </div>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                    :visible="false"
                  >
                    <div class="buying-option-body">
                      <div class="alert alert-primary p-1 mt-1">
                        {{ $t('Only available in Turkey') }}
                      </div>
                      <validation-observer
                        ref="mobileForm"
                      >
                        <b-form
                          class="mt-2"
                          @submit.prevent
                        >
                          <b-row v-show="false">
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="firstname"
                                :label="$t('Firstname')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Firstname')"
                                  vid="firstName"
                                >
                                  <b-form-input
                                    id="firstname"
                                    v-model="mobilePayment.firstname"
                                    name="firstname"
                                    disabled
                                    :placeholder="$t('Firstname')"
                                    :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="lastname"
                                :label="$t('Lastname')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Lastname')"
                                  vid="lastname"
                                >
                                  <b-form-input
                                    id="lastname"
                                    v-model="mobilePayment.lastname"
                                    name="lastname"
                                    disabled
                                    :placeholder="$t('Lastname')"
                                    :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-show="false">
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="email"
                                :label="$t('Email')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Email')"
                                  vid="email"
                                >
                                  <b-form-input
                                    id="email"
                                    v-model="mobilePayment.email"
                                    name="email"
                                    disabled
                                    :placeholder="$t('Email')"
                                    :state="Array.isArray(errors) && errors.length > 0 ? false : null"
                                  />
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                            <b-col
                              md="6"
                              sm="12"
                            >
                              <b-form-group
                                label-for="phone"
                                :label="$t('Phone number')"
                              >
                                <validation-provider
                                  #default="{ errors }"
                                  :name="$t('Phone number')"
                                  vid="phone"
                                >
                                  <vue-tel-input
                                    v-model="mobilePayment.phone"
                                    mode="auto"
                                    disabled
                                    :input-options="{placeholder: $t('Phone number')}"
                                    valid-characters-only
                                    :style-classes="['phone-input']"
                                    @open="onDropdownOpen(true)"
                                    @close="onDropdownOpen(false)"
                                    @country-changed="countryChanged"
                                    @validate="phoneValidate"
                                  >
                                    <template v-slot:arrow-icon>
                                      <span class="font-small-3 mr-05">
                                        {{ country ? '+' + country.dialCode : '' }}
                                      </span>
                                      <font-awesome-icon
                                        v-if="open"
                                        icon="fa-solid fa-chevron-up"
                                      />
                                      <font-awesome-icon
                                        v-else
                                        icon="fa-solid fa-chevron-down"
                                      />
                                    </template>
                                  </vue-tel-input>
                                  <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-form>
                      </validation-observer>
                      <b-row>
                        <b-col md="12">
                          <h5 class="fs-12 color-gray">
                            {{ $t('Payment total') }}
                          </h5>
                        </b-col>
                        <b-col
                          md="6"
                          sm="12"
                        >
                          <div
                            v-if="mobileProduct"
                            class="mobile-payment-details"
                          >
                            <div class="mobile-payment-details-item">
                              <div
                                class="mobile-payment-details-item-title"
                                style="margin-left: -22px"
                              >
                                <b-img
                                  :src="iconDiamonds1"
                                  width="20"
                                />
                                {{ $t('Package amount') }}
                              </div>
                              <div class="mobile-payment-details-item-value mb-05">
                                ₺{{ parseFloat(mobileProduct.amount - product.amount) > 0 ? parseFloat(mobileProduct.amount - (mobileProduct.amount - product.amount)).toFixed(2) : parseFloat(mobileProduct.amount).toFixed(2) }}
                                - {{ mobileProduct.credits }} {{ $t('Diamonds') }}
                              </div>
                              <div class="mobile-payment-details-item-title">
                                {{ $t('Mobile payment service provider fee') }}
                              </div>
                              <div class="mobile-payment-details-item-value mb-05">
                                ₺{{ parseFloat(mobileProduct.amount - product.amount) > 0 ? parseFloat(mobileProduct.amount - product.amount).toFixed(2) : '0.00' }}
                              </div>
                            </div>
                            <hr>
                            <div class="mobile-payment-details-total">
                              <div class="mobile-payment-details-item-title">
                                {{ $t('Total amount') }}
                              </div>
                              <div class="mobile-payment-details-item-value">
                                ₺{{ parseFloat(mobileProduct.amount).toFixed(2) }}
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          class="mt-2 mb-1 pl-0"
                          xl="6"
                          lg="6"
                          md="12"
                        >
                          <b-button
                            variant="primary"
                            block
                            class=""
                            @click="buyMobilePurchase"
                          >
                            <div class="button-secure-icon">
                              <b-img
                                :src="iconSecureBuying"
                                class=""
                                height="26"
                              />
                            </div>
                            <div class="d-flex flex-column">
                              <span>
                                {{ $t('Buy') }}
                              </span>
                              <span class="fs-10 color-gray">
                                {{ $t('You will continue the payment on PayByMe') }}
                              </span>
                            </div>
                          </b-button>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col
                          class="d-flex flex-column align-items-center justify-content-center mb-2"
                          xl="6"
                          lg="6"
                          md="12"
                        >
                          <p class="fs-12 color-black">
                            {{ $t('How do I open my line for mobile payment?') }}
                          </p>
                          <p class="fs-12 color-gray-2 fw-400 mt--1">
                            <a
                              href="https://www.turkcell.com.tr/yardim/servisler-ve-internet/servisler/telefonumu-mobil-odeme-kullanimina-nasil-acabilirim"
                              class="text-decoration-underline"
                              target="_blank"
                            >
                              Turkcell
                            </a>
                            <a
                              href="https://www.vodafone.com.tr/Servisler/cep-cuzdan-mobil-odeme.php"
                              class="text-decoration-underline"
                              target="_blank"
                            >
                              Vodafone
                            </a>
                            <a
                              v-if="false"
                              href="https://bireysel.turktelekom.com.tr/mobil/web/servisler/sayfalar/turk-telekom-mobil-odeme.aspx"
                              class="text-decoration-underline"
                              target="_blank"
                            >
                              Turk Telekom
                            </a>
                          </p>
                        </b-col>
                      </b-row>
                    </div>
                  </b-collapse>
                </div>
                <div class="buying-option">
                  <div
                    v-b-toggle.accordion-3
                    class="buying-option-header"
                    role="tab"
                  >
                    <div>
                      {{ $t('Payment by Wire Transfer / EFT / FAST') }}
                    </div>
                    <div class="fs-14 color-gray fw-300">
                      {{ $t('Easily pay into our bank accounts') }}
                    </div>
                  </div>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                    :visible="false"
                  >
                    <div class="buying-option-body">
                      <h5 class="color-gray mt-2">
                        {{ $t('How to Pay Boho Live by Wire Transfer?') }}
                      </h5>
                      <p>
                        {{ $t('From your personal bank account or if you do not have a bank account, you can send the amount up to the minute you want to buy, via wire transfer or eft, from any ATM to one of the bank accounts of Boho Live below.') }}
                        <span
                          class="cursor-pointer text-decoration-underline fw-600"
                          @click="showTransferPaymentDetail = !showTransferPaymentDetail"
                        >
                          {{ $t('Click for more detailed information') }}.
                        </span>
                      </p>
                      <div
                        v-if="showTransferPaymentDetail"
                        class="mb-2 mt-2"
                      >
                        <div class="d-flex justify-content-between mb-1">
                          <div class="d-flex flex-column align-items-center">
                            <b-img :src="bankIcon1" />
                            <div
                              style="width: 180px"
                              class="text-center fw-600"
                            >
                              {{ $t('Internet and Telephone Banking') }}
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            {{ $t('The name of the account owner appears as the sender, since the transfers you make over the Internet and telephone banking are made from your account. Send us your Boho Live account information with your receipt, and it will be uploaded to the diamond\'s account.') }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-between mb-1">
                          <div class="d-flex flex-column align-items-center">
                            <b-img :src="bankIcon2" />
                            <div
                              style="width: 180px"
                              class="text-center fw-600"
                            >
                              {{ $t('From the bank branch') }}
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            {{ $t('Even if you do not have a bank account of your own, you can make money transfers from bank branches. Indicate the amount you want to deposit by forwarding the Boho Live bank account information to the bank officer. Then, send us the receipt and Boho Live account information given to you, and the diamond will be loaded into your account.') }}
                          </div>
                        </div>
                        <div class="d-flex justify-content-between mb-1">
                          <div class="d-flex flex-column align-items-center">
                            <b-img :src="bankIcon3" />
                            <div
                              style="width: 180px"
                              class="text-center fw-600"
                            >
                              {{ $t('ATM') }}
                            </div>
                          </div>
                          <div class="d-flex align-items-center">
                            {{ $t('You do not need to have a card to make money transfers from ATMs. At the end of the transaction, you can send the copy of the receipt you will receive from the ATM or the transaction number and Boho Live account information on the receipt, and you can have the diamond loaded into your account.') }}
                          </div>
                        </div>
                      </div>
                      <b-alert
                        show
                        variant="warning"
                        class="p-2"
                      >
                        {{ $t('After making the transfer, you need to send the receipt, bank name, username and phone number in Boho Live to the support@boholive.app e-mail address.') }}
                      </b-alert>
                      <h5 class="color-gray">
                        {{ $t('Boho Live Account Information') }}
                      </h5>
                      <div class="mt-1">
                        <b-row>
                          <b-col
                            lg="6"
                            md="6"
                            sm="12"
                          >
                            <b-card
                              tag="article"
                              class="mb-2"
                            >
                              <div class="text-center mb-1">
                                <a
                                  href="https://www.yapikredi.com.tr/"
                                  target="_blank"
                                >
                                  <b-img
                                    :src="bankYapiKredi"
                                    height="54"
                                  />
                                </a>
                              </div>
                              <b-card-text>
                                <b>{{ $t('Bank') }} : </b> Yapı Kredi Bankası <br>
                                <b>{{ $t('IBAN') }} : </b> TR77 0006 7010 0000 0045 0168 00 <br>
                                <b>{{ $t('RECEIVER') }} : </b> Arasana
                              </b-card-text>
                            </b-card>
                          </b-col>
                          <b-col
                            lg="6"
                            md="6"
                            sm="12"
                          >
                            <b-card
                              tag="article"
                              class="mb-2"
                            >
                              <div class="text-center mb-1">
                                <a
                                  href="https://www.garantibbva.com.tr/"
                                  target="_blank"
                                >
                                  <b-img
                                    :src="bankGaranti"
                                    height="54"
                                  />
                                </a>
                              </div>
                              <b-card-text>
                                <b>{{ $t('Bank') }} : </b> Garanti Bankası <br>
                                <b>{{ $t('IBAN') }} : </b> TR14 0006 2001 4700 0006 2991 86 <br>
                                <b>{{ $t('RECEIVER') }} : </b> Arasana
                              </b-card-text>
                            </b-card>
                          </b-col>
                          <b-col
                            lg="6"
                            md="6"
                            sm="12"
                          >
                            <b-card
                              tag="article"
                              class="mb-2"
                            >
                              <div class="text-center mb-1">
                                <a
                                  href="https://www.isbank.com.tr/"
                                  target="_blank"
                                >
                                  <b-img
                                    :src="bankIsbank"
                                    height="54"
                                  />
                                </a>
                              </div>
                              <b-card-text>
                                <b>{{ $t('Bank') }} : </b> İş Bankası <br>
                                <b>{{ $t('IBAN') }} : </b> TR60 0006 4000 0011 0520 9890 33 <br>
                                <b>{{ $t('RECEIVER') }} : </b> Arasana
                              </b-card-text>
                            </b-card>
                          </b-col>
                          <b-col
                            lg="6"
                            md="6"
                            sm="12"
                          >
                            <b-card
                              tag="article"
                              class="mb-2"
                            >
                              <div class="text-center mb-1">
                                <a
                                  href="https://www.akbank.com/tr-tr/sayfalar/default.aspx"
                                  target="_blank"
                                >
                                  <b-img
                                    :src="bankAkbank"
                                    height="54"
                                  />
                                </a>
                              </div>
                              <b-card-text>
                                <b>{{ $t('Bank') }} : </b> Akbank <br>
                                <b>{{ $t('IBAN') }} : </b> TR21 0004 6010 2488 8000 1015 31 <br>
                                <b>{{ $t('RECEIVER') }} : </b> Arasana
                              </b-card-text>
                            </b-card>
                          </b-col>
                          <b-col
                            lg="6"
                            md="6"
                            sm="12"
                          >
                            <b-card
                              tag="article"
                              class="mb-2"
                              style="height: 170px"
                            >
                              <div class="text-center mb-1">
                                <a
                                  href="https://www.ziraatbank.com.tr/tr"
                                  target="_blank"
                                >
                                  <b-img
                                    :src="bankZiraat"
                                    height="42"
                                  />
                                </a>
                              </div>
                              <b-card-text>
                                <b>{{ $t('Bank') }} : </b> Ziraat Bankası <br>
                                <b>{{ $t('IBAN') }} : </b> TR 4100 0100 2507 8053 6226 5004 <br>
                                <b>{{ $t('RECEIVER') }} : </b> Arasana
                              </b-card-text>
                            </b-card>
                          </b-col>
                        </b-row>
                      </div>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>

    <form
      v-if="paymentData"
      ref="posnetForm"
      method="post"
      :action="posnetUrl"
    >
      <input
        id="posnetData"
        name="posnetData"
        type="hidden"
        :value="paymentData.posnetData"
      >
      <input
        id="posnetData2"
        name="posnetData2"
        type="hidden"
        :value="paymentData.posnetData2"
      >
      <input
        id="mid"
        name="mid"
        type="hidden"
        :value="paymentData.mid"
      >
      <input
        id="posnetID"
        name="posnetID"
        type="hidden"
        :value="paymentData.posnetId"
      >
      <input
        id="sign"
        name="digest"
        type="hidden"
        :value="paymentData.digest"
      >
      <input
        id="merchantReturnURL"
        name="merchantReturnURL"
        type="hidden"
        :value="callbackUrl"
      >
      <input
        id="lang"
        name="lang"
        type="hidden"
        value="tr"
      >
      <input
        id="url"
        name="url"
        type="hidden"
        :value="currentUrl"
      >
    </form>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BAlert, BButton, BCard, BCardText, BCol, BCollapse, BForm, BFormGroup, BFormInput, BImg, BInputGroup,
  BInputGroupAppend, BOverlay, BRow,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import {
  throwDefaultError, throwPaymentError, convertTrChars, numberFormat,
} from '@/helpers/helpers'
import vSelect from 'vue-select'
// import * as Card from 'card'
import { mask } from 'vue-the-mask'

const posnetUrl = process.env.VUE_APP_POSNET_URL
const appUrl = process.env.VUE_APP_URL

const bankAkbank = require('@/assets/images/banks/akbank.png')
const bankGaranti = require('@/assets/images/banks/garanti.png')
const bankIsbank = require('@/assets/images/banks/isbank.png')
const bankYapiKredi = require('@/assets/images/banks/yapikredi.jpeg')
const bankZiraat = require('@/assets/images/banks/ziraat.jpg')
const bankIcon1 = require('@/assets/images/banks/icon_1.png')
const bankIcon2 = require('@/assets/images/banks/icon_2.png')
const bankIcon3 = require('@/assets/images/banks/icon_3.png')
const iconDiamonds1 = require('@/assets/images/icons/diamonds-1.svg')
const iconSecureBuying = require('@/assets/images/icons/secure-buying.svg')

export default {
  directives: { mask },
  components: {
    BAlert,
    BButton,
    BCard,
    BCardText,
    BCol,
    BCollapse,
    BForm,
    BFormGroup,
    BFormInput,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BRow,

    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  data() {
    return {
      bankAkbank,
      bankGaranti,
      bankIcon1,
      bankIcon2,
      bankIcon3,
      bankIsbank,
      bankYapiKredi,
      bankZiraat,
      callbackUrl: `${appUrl}/payment/credit-card/finalize`,
      creditCard: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        number: '',
        month: '',
        year: '',
        cvv: '',
      },
      country: null,
      couponCode: '',
      couponCodeBonusRatio: null,
      couponCodeInfoColor: 'color-gray',
      couponCodeInfoText: this.$t('Coupon is single use, not reusable'),
      couponCodeSucceed: false,
      iconDiamonds1,
      iconSecureBuying,
      loading: false,
      mobilePayment: {
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      mobileProduct: null,
      months: [
        'Ay',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
      ],
      open: false,
      paybymeRedirectUrl: 'https://order.payby.me/webpayment/Pay.aspx',
      paymentData: null,
      posnetUrl,
      product: null,
      productId: null,
      showTransferPaymentDetail: false,
      valid: false,
    }
  },
  computed: {
    currentUrl() {
      const route = this.$router.resolve({ name: 'buy', params: { id: this.productId } })

      return appUrl + route?.href
    },
    paymentHtml() {
      if (!this.paymentData) return ''

      return `
      <div>
        <form v-if="paymentData" ref="posnetForm" id="posnetForm" method="post" action="${this.posnetUrl}">
          <input name="posnetData" type="hidden" id="posnetData" value="${this.paymentData.posnetData}">
          <input name="posnetData2" type="hidden" id="posnetData2" value="${this.paymentData.posnetData2}">
          <input name="mid" type="hidden" id="mid" value="${this.paymentData.mid}">
          <input name="posnetID" type="hidden" id="posnetID" value="${this.paymentData.posnetId}">
          <input name="digest" type="hidden" id="sign" value="${this.paymentData.digest}">
          <input name="merchantReturnURL" type="hidden" id="merchantReturnURL" value="${this.callbackUrl}">
          <input name="lang" type="hidden" id="lang" value="tr">
          <input name="url" type="hidden" id="url" value="${this.currentUrl}">
        </form>
        <script>
          setTimeout(() => {
              console.log(document.getElementById('posnetForm'))
              document.getElementById('posnetForm').submit();
          }, 333);
        <\/script>
      </div>
      `
    },
    years() {
      const years = [
        this.$t('Year'),
      ]
      const currentYear = new Date().getFullYear()
      for (let i = 0; i < 10; i++) {
        years.push((currentYear + i).toString())
      }
      return years
    },
  },
  mounted() {
    // new Card({
    //   form: "form#cc-form",
    //   container: ".card-wrapper",
    //   formSelectors: {
    //     numberInput: "input#number",
    //     expiryInput: "input#cc-expiration",
    //     cvcInput: "input#cvv",
    //     nameInput: "input#name"
    //   },
    //   formatting: true,
    //   placeholders: {
    //     number: "•••• •••• •••• ••••",
    //     name: "Ad Soyad",
    //     expiry: "••/••",
    //     cvc: "•••"
    //   }
    // })
  },
  created() {
    this.productId = this.$route.params.productId
    this.couponCode = this.$route.query.code || ''
    this.couponCodeBonusRatio = this.$route.query.ratio || ''
    this.getProduct()

    // Otomatik mevcut ay seçme açılırsa ay tek hane olduğunda başına '0' eklenecek
    // this.creditCard.month = (new Date().getMonth() + 1).toString()
    // this.creditCard.year = new Date().getFullYear().toString()

    // this.creditCard.month = this.$t('Month')
    // this.creditCard.year = this.$t('Year')

    const user = this.$store.getters['auth/user']

    this.creditCard.firstname = user.firstName || ''
    this.creditCard.lastname = user.lastName || ''
    this.creditCard.phone = user.phoneNumber || ''
    this.creditCard.email = user.email || ''

    this.mobilePayment.firstname = user.firstName || ''
    this.mobilePayment.lastname = user.lastName || ''
    this.mobilePayment.phone = user.phoneNumber || ''
    this.mobilePayment.email = user.email || ''
  },
  methods: {
    applyCouponCode() {
      this.loading = true

      useJwt.couponCodeCheckBeforePurchasing({ code: this.couponCode })
        .then(response => {
          const data = response.data.body

          console.log('couponCodeCheckBeforePurchasing data', data)
          if (data.valid) {
            this.couponCodeBonusRatio = data.bonusRatio || this.couponCodeBonusRatio
            this.couponCodeSucceed = true
            this.couponCodeInfoColor = 'color-green'
            this.couponCodeInfoText = this.$t('Your {ratio} bonus coupon has been recognized. It will be credited to your account along with your diamonds after you make your payment.', { ratio: `%${(data.bonusRatio || this.couponCodeBonusRatio) * 100}` })
          } else {
            this.couponCodeSucceed = false
            this.couponCodeInfoColor = 'color-red-light'
            this.couponCodeInfoText = this.$t('You entered an incorrect or invalid coupon code. Check your details and try again. In case of problems, contact Boho Assistant.')
          }
        })
        .catch(() => {
          this.couponCodeSucceed = false
          this.couponCodeInfoColor = 'color-red-light'
          this.couponCodeInfoText = this.$t('You entered an incorrect or invalid coupon code. Check your details and try again. In case of problems, contact Boho Assistant.')
        })
        .finally(() => { this.loading = false })
    },
    back() {
      this.$router.back()
    },
    buyWithCreditCard() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true
          const data = {
            creditCard: {
              cardNumber: this.creditCard.number.replace(/\s/g, '').replace(/-/g, ''),
              expiryMonth: this.creditCard.month,
              expiryYear: this.creditCard.year,
              cardCVV: this.creditCard.cvv,
            },
            email: this.creditCard.email,
            firstName: convertTrChars(this.creditCard.firstname),
            lastName: convertTrChars(this.creditCard.lastname),
            phoneNumber: this.creditCard.phone.replace(/\s/g, ''),
            productId: this.productId,
          }
          useJwt.paymentPostnetInit(data)
            .then(response => {
              try {
                this.paymentData = response.data.body
                localStorage.setItem('LAST_PAYMENT_TRANSACTION_ID_KEY', this.paymentData.paymentTransactionId)
                localStorage.setItem('LAST_PAYMENT_AMOUNT', this.product.amount)
                if (this.couponCodeSucceed) {
                  localStorage.setItem('LAST_COUPON_CODE', this.couponCode)
                } else {
                  localStorage.removeItem('LAST_COUPON_CODE')
                }
                setTimeout(() => {
                  // this.$refs.posnetForm.submit()
                  this.$store.dispatch('payment/setPaymentData', this.paymentData)
                  this.$store.dispatch('payment/setPaymentHtml', this.paymentHtml)
                  // this.$store.dispatch('payment/setLoading', true)
                  this.$store.dispatch('payment/setStatus', true)
                }, 333)
              } catch (e) {
                throwDefaultError(this.$toast)
              }
            })
            .catch(error => {
              throwPaymentError(this.$toast, error)
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    buyMobilePurchase() {
      this.loading = true
      useJwt.paybymePurchase({ productId: this.mobileProduct.productId })
        .then(response => {
          try {
            const result = response.data.body
            if (result.status === 1) {
              this.redirectToPaybyme(result.errorDesc)
            }
          } catch (e) {
            throwDefaultError(this.$toast)
          }
        })
        .catch(error => {
          throwDefaultError(this.$toast, error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    countryChanged(country) {
      this.country = country
    },
    getProduct() {
      this.loading = true

      useJwt.getProducts()
        .then(response => {
          this.product = response.data.body.find(p => p.productId === this.productId)
          this.mobileProduct = response.data.body.find(p => p.sku === this.product.sku && p.productId.includes('com.arasana.app.mobile.'))
        })
        .catch(error => {
          throwDefaultError(this.$toast, error)
        })
        .finally(() => { this.loading = false })
    },
    numberFormat,
    onDropdownOpen(open) {
      this.open = open
    },
    phoneValidate(status) {
      this.valid = status ? status.valid : false
    },
    redirectToPaybyme(hash) {
      if (window) {
        if (sessionStorage) {
          const mobilePaymentOrder = {
            product: this.mobileProduct,
            transactionId: hash,
          }
          sessionStorage.setItem('arsnMobilePaymentOrder', JSON.stringify(mobilePaymentOrder))
        }

        window.location.href = `${this.paybymeRedirectUrl}?hash=${hash}`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.page-market-buy {

}
.buying-options {
  .buying-option {
    .buying-option-header {
      background-color: #fff;
      border: 1px solid #000000;
      border-radius: 12px;
      padding: 10px 20px;
      font-family: 'Lexend', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      margin-bottom: 20px;
      cursor: pointer;

      &.not-collapsed {
        background-color: #CFD6DD;
      }

      .badge-advantage {
        position: absolute;
        right: 30px;
        top: -16px;
        background: #16E481;
        border-radius: 100px;
        font-family: 'Lexend', sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        padding: 10px 8px;
      }
    }

    .buying-option-body {
      margin-top: -40px;
      padding-top: 20px;
      margin-bottom: 20px;
    }
  }
}
.button-secure-icon {
  position: absolute;
  height: 100%;
  left: 32px;
  top: 0px;
  display: flex;
  align-items: center;
}
input {
  padding: 24px 16px !important;
  border-radius: 12px !important;
}
.mobile-payment-details {
  background: #F5F8FD;
  border-radius: 12px;
  padding: 16px 46px;

  .mobile-payment-details-item-title {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    color: #868C92;
  }

  .mobile-payment-details-item-value {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #2A3240;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    width: calc(100% + 92px);
    border-top: 1px solid #CFD6DD;
    margin-left: -46px;
  }
}
.color-green {
  color: #16E481 !important;
}
.color-red-light {
  color: #FC668A !important;
}
</style>

<style lang="scss">
.vs__dropdown-toggle {
  padding: 8px 4px !important;
  border-radius: 12px !important;
}
</style>
